.data-table {
  font-family: sans-serif;
  font-size: 1.6rem;
  border-collapse: collapse;
  width: 100%;
}

.data-table td,
th {
  border: 1px solid #ddd;
  padding: 0.8rem;
  text-align: center;
}

.data-table tr:nth-child(even) {
  background-color: lightgray;
}

.data-table tr:nth-child(odd) {
  background-color: rgb(240, 240, 240);
}

.data-table tr:hover {
  filter: brightness(95%);
}

.data-table th {
  padding-top: 1.2rem;
  padding-bottom: 1.2rem;

  /* // background-color: #04AA6D; */
  /* // color: white; */
  border-top: 3px solid gray;
}
