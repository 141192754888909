@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);
@import url(https://js.arcgis.com/4.23/@arcgis/core/assets/esri/themes/light/main.css);




.choose-viz {
    
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    border-bottom: #616161 1px solid;
    
}
.choose-year {
    
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    border-bottom: #616161 1px solid;
    
}

.choose-title {
    font-weight: 300;
    font-size: 1rem;
    
    padding: 1rem;
    text-align: center;
}

.button {
    padding: 1rem;
}


.choose-rcp {
    
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    border-bottom: #616161 1px solid;

}


.choose-season {
    
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center; 
    /* border-bottom: black 1px solid; */
}



.viz-map {
    /* background-color: #ddd; */
    /* background-color: #E5E2E0; */
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 66%;
    display: flex;
    /* padding: 1em; */
    /* margin: 1em; */
}

.viz-map img {
    /* NRCC pngs max width should not be larger than 766 */
    /* max-height: 600px; */
    margin-top: 1em;
    
        
    }

    .map-title {
        font-weight: 500;
    }
    
    .change-text {
        width: 10em;
        height: 10em;
    }
    .map-container {
        display: flex;
        align-items: flex-start;
    }

.viz-next-to-map-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 66%;
}

.viz-selector {
    display: flex;
    align-items: center;
    margin: 0.5rem;
    font-size: 1.5rem;
    text-align: center;
}

.chart-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.viz-stats {
    display: flex;    
    /* flex: 2; */
    margin-bottom: 2rem;
    align-self: center;

}
.viz-legend {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 2em;  
    width: 177px;
    

}
.viz-legend img {
    width: 200px;
}

.chart-container {
    display: flex;
     /* width: 100vw;  */
     /* z-index: -1;     */
}


.space {
    height: 0.25em;
}

.tool-bottom {
    width: 100%;
    background-color: #f4f4f4;
    flex-direction: column;
    padding: 5rem auto;
    margin-bottom: 5rem;
}




.tool-bottom img {
  max-width: 300px;
}

.tool-bottom h2, .tool-bottom p {
  padding: 1rem;
  max-width: 850px;
}



.generic-button {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  background-color: #ddd;
  color: #616161;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.5);
  cursor: pointer;
  width: 10em;
  height: 2em;
  text-align: center;
  margin: 0.5em;
  border-radius: 0.5rem;
}

.data-table {
  font-family: sans-serif;
  font-size: 1.6rem;
  border-collapse: collapse;
  width: 100%;
}

.data-table td,
th {
  border: 1px solid #ddd;
  padding: 0.8rem;
  text-align: center;
}

.data-table tr:nth-child(even) {
  background-color: lightgray;
}

.data-table tr:nth-child(odd) {
  background-color: rgb(240, 240, 240);
}

.data-table tr:hover {
  -webkit-filter: brightness(95%);
          filter: brightness(95%);
}

.data-table th {
  padding-top: 1.2rem;
  padding-bottom: 1.2rem;

  /* // background-color: #04AA6D; */
  /* // color: white; */
  border-top: 3px solid gray;
}

