.generic-button {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  background-color: #ddd;
  color: #616161;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.5);
  cursor: pointer;
  width: 10em;
  height: 2em;
  text-align: center;
  margin: 0.5em;
  border-radius: 0.5rem;
}
