

.tool-bottom img {
  max-width: 300px;
}

.tool-bottom h2, .tool-bottom p {
  padding: 1rem;
  max-width: 850px;
}


