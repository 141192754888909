

.choose-viz {
    
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    border-bottom: #616161 1px solid;
    
}
.choose-year {
    
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    border-bottom: #616161 1px solid;
    
}

.choose-title {
    font-weight: 300;
    font-size: 1rem;
    
    padding: 1rem;
    text-align: center;
}

.button {
    padding: 1rem;
}


.choose-rcp {
    
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    border-bottom: #616161 1px solid;

}


.choose-season {
    
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center; 
    /* border-bottom: black 1px solid; */
}



.viz-map {
    /* background-color: #ddd; */
    /* background-color: #E5E2E0; */
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 66%;
    display: flex;
    /* padding: 1em; */
    /* margin: 1em; */
}

.viz-map img {
    /* NRCC pngs max width should not be larger than 766 */
    /* max-height: 600px; */
    margin-top: 1em;
    
        
    }

    .map-title {
        font-weight: 500;
    }
    
    .change-text {
        width: 10em;
        height: 10em;
    }
    .map-container {
        display: flex;
        align-items: flex-start;
    }

.viz-next-to-map-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 66%;
}

.viz-selector {
    display: flex;
    align-items: center;
    margin: 0.5rem;
    font-size: 1.5rem;
    text-align: center;
}

.chart-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.viz-stats {
    display: flex;    
    /* flex: 2; */
    margin-bottom: 2rem;
    align-self: center;

}
.viz-legend {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 2em;  
    width: 177px;
    

}
.viz-legend img {
    width: 200px;
}

.chart-container {
    display: flex;
     /* width: 100vw;  */
     /* z-index: -1;     */
}


.space {
    height: 0.25em;
}

.tool-bottom {
    width: 100%;
    background-color: #f4f4f4;
    flex-direction: column;
    padding: 5rem auto;
    margin-bottom: 5rem;
}

